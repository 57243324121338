import React from "react";
import ogpImage from "../../images/ogp.png";
import favicon from "../../images/favicon.png";
import Helmet from "react-helmet";
import { usePagePath, makeText, useLang } from "helpers/i18n";

// ----------------------------------------------------------------
//     テキスト
// ----------------------------------------------------------------

const useText = makeText({
  en: {
    title: "DroidKaigi 2020 Lite",
    description:
      "DroidKaigi 2020 Lite is a developer-first Android conference" +
      " that will be held online on Aug 27 - 29. Join us with online" +
      " sessions, fireside chats, and codelabs.",
  },
  ja: {
    title: "DroidKaigi 2020 Lite",
    description:
      "エンジニアが主役の Android カンファレンス DroidKaigi 2020 Lite " +
      "を 8/27〜8/29 にオンライン開催します。" +
      "オンラインでのセッションの他、fireside chat やコードラボも行う予定です。",
  },
});

// ----------------------------------------------------------------
//     コンポーネント
// ----------------------------------------------------------------

const Metadata: React.FC<{ title?: string; description?: string }> = ({
  title,
  description,
}) => {
  const lang = useLang();
  const pathname = usePagePath();
  const text = useText();
  const fullTitle = title ? `${title} | ${text.title}` : text.title;
  const prefix = "https://2020-lite.droidkaigi.jp";

  return (
    <Helmet htmlAttributes={{ lang }}>
      <title>{fullTitle}</title>
      <meta name="description" content={text.description} />
      <link rel="icon" type="image/png" href={favicon} />
      {/* Required Open Graph Info */}
      <meta property="og:title" content={fullTitle} />
      <meta
        property="og:description"
        content={description || text.description}
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`${prefix}${pathname}`} />
      <meta property="og:image" content={`${prefix}${ogpImage}`} />
      <meta property="og:site_name" content="DroidKaigi" />
      {/* Twitter Cards */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@DroidKaigi" />
    </Helmet>
  );
};

export default Metadata;
